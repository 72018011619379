<template>
  <div class="newsDetail">
    <TopNav></TopNav>
    <!-- 背景开始 -->
    <div class="top-bg">
      <div class="top-bg-content">
        <div class="top-content-bannar">
          <div class="top-bannar-vis">
            <div>新闻动态</div>
            <div>
              用中标资讯、运营干货一键掌握
            </div>
          </div>
        </div>
      </div>
      <div class="top-bg-content-phone">
        <div class="top-content-bannar">
          <div class="top-bannar-vis">
            <div>优越新闻动态</div>
            <div>
              新闻动态抢先看，从权益输出到品牌赋能
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 背景结束 -->

    <!-- 详情全部开始 -->
    <div class="contentDetail">
      <p class="detail-nav">
        <a @click="goback">新闻动态></a>
        <span class="title">
          {{ this.detail.title }}
        </span>
      </p>
      <p class="detail-title">
        {{ this.detail.title }}
      </p>
      <p class="detail-time">
        <span class="fl">{{ this.detail.created_at }}</span>
        <span class="fr">阅读量：{{ this.detail.look }}</span>
      </p>
      <i class="time-border"></i>
      <div class="detail-all" v-html="this.detail.content"></div>
      <div class="detail-page">
        <span class="left" @click="prev">上一篇</span>
        <span class="right" @click="next">下一篇</span>
      </div>
    </div>
    <!-- 详情全部结束 -->

    <!-- 移动--详情全部开始 -->
    <div class="contentDetail-phone">
      <p class="phoneDetail-title">
        {{ this.detail.title }}
      </p>
      <div class="line-active">
        <span></span>
      </div>
      <div class="phoneDetail-timeAll">
        <div class="phoneDetail-time fl">
          {{ this.detail.created_at }}
        </div>
        <div class="phoneDetail-time fr">阅读量：{{ this.detail.look }}</div>
      </div>
      <div class="phoneDetail-all" v-html="this.detail.content"></div>
      <div class="detail-page-phone">
        <span class="left" @click="prev">上一篇</span>
        <span class="right" @click="next">下一篇</span>
      </div>
    </div>
    <!-- 移动--详情全部结束 -->

    <Bottom></Bottom>
  </div>
</template>

<script>
import "../assets/less/newsDetail.less";
import TopNav from "../components/topNav";
import Bottom from "../components/Bottom";
export default {
  data() {
    return {
      detail: {},
      id: 0
    };
  },
  created() {
    let id = Number(this.$route.query.id);
    this.id = id;
    this.axios({
      //请求方式
      method: "GET",
      url: "https://yxwechat.youxiangchewang.com/article/article",
      params: {
        id: this.id
      }
    })
      .then(res => {
        if (res.data.code === 0) {
          this.detail = res.data.data;
        } else {
          this.$toast({
            message: res.data.msg,
            forbidClick: true,
            duration: 3000
          });
        }
      })
      .catch(err => {
        this.$toast.clear();
        console.log("err ==> ", err);
      });
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
  methods: {
    prev() {
      if (this.detail.prev == 0) {
        this.$toast({
          message: "文章不存在!",
          forbidClick: true,
          duration: 3000
        });
        return;
      }
      this.$router.replace("/newsDetail?id=" + this.detail.prev);
      /**  刷新当前界面 */
      this.$router.go(0);
    },
    next() {
      if (this.detail.next == 0) {
        this.$toast({
          message: "文章不存在!",
          forbidClick: true,
          duration: 3000
        });
        return;
      }
      this.$router.replace("/newsDetail?id=" + this.detail.next);
      /**  刷新当前界面 */
      this.$router.go(0);
    },
    goback() {
      this.$router.go(-1);
    }
  },
  components: {
    TopNav,
    Bottom
  }
};
</script>

<style lang="less" scoped></style>
